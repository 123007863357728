export const bubbleSort = (array: number[]): number[][] => {
    const animations: number[][] = [];
    const n = array.length;

    for (let i = 0; i < n - 1; i++) {
        for (let j = 0; j < n - i - 1; j++) {
            animations.push([j, array[j], j + 1, array[j + 1]]);
            if (array[j] > array[j + 1]) {
                const temp = array[j];
                array[j] = array[j + 1];
                array[j + 1] = temp;

                // Push the indices and heights of the two elements being swapped
                animations.push([j, array[j], j + 1, array[j + 1]]);
            }
        }
    }

    return animations;
};

export const quickSort = (array: number[]): number[][] => {
    const animations: number[][] = [];

    const partition = (low: number, high: number): number => {
        const pivot = array[high];
        let i = low - 1;

        for (let j = low; j < high; j++) {
            animations.push([j, high]);
            if (array[j] < pivot) {
                i++;
                [array[i], array[j]] = [array[j], array[i]];
                animations.push([i, array[i], j, array[j]]);
            }
        }

        [array[i + 1], array[high]] = [array[high], array[i + 1]];
        animations.push([i + 1, array[i + 1], high, array[high]]);

        return i + 1;
    };

    const quickSortHelper = (low: number, high: number): void => {
        if (low < high) {
            const pivotIndex = partition(low, high);

            quickSortHelper(low, pivotIndex - 1);
            quickSortHelper(pivotIndex + 1, high);
        }
    };

    quickSortHelper(0, array.length - 1);

    return animations;
};



// Selection Sort
export const selectionSort = (array: number[]): number[][] => {
    const animations: number[][] = [];
    const n = array.length;

    for (let i = 0; i < n - 1; i++) {
        let minIndex = i;

        for (let j = i + 1; j < n; j++) {
            // Push indices to highlight during the sorting process
            animations.push([i, j]);

            if (array[j] < array[minIndex]) {
                minIndex = j;
            }
        }

        // Swap elements
        [array[i], array[minIndex]] = [array[minIndex], array[i]];

        // Push the indices and heights of the two elements being swapped
        animations.push([i, array[i], minIndex, array[minIndex]]);
    }

    return animations;
};


// Insertion Sort
export const insertionSort = (array: number[]): number[][] => {
    const animations: number[][] = [];
    const n = array.length;

    for (let i = 1; i < n; i++) {
        const key = array[i];
        let j = i - 1;

        // Push indices to highlight during the sorting process
        animations.push([i, j]);

        while (j >= 0 && array[j] > key) {
            // Swap elements
            array[j + 1] = array[j];

            // Push the indices and heights of the two elements being swapped
            animations.push([j + 1, array[j + 1], j, array[j]]);

            j--;
        }

        array[j + 1] = key;

        // Push the index and height of the element being moved to its correct position
        animations.push([j + 1, array[j + 1]]);
    }

    return animations;
};

// Merge Sort
export const mergeSort = (array: number[]): number[][] => {
    const animations: number[][] = [];

    const merge = (start: number, mid: number, end: number): void => {
        const leftArray = array.slice(start, mid + 1);
        const rightArray = array.slice(mid + 1, end + 1);

        let i = 0,
            j = 0,
            k = start;

        while (i < leftArray.length && j < rightArray.length) {
            // Push indices to highlight during the sorting process
            animations.push([start + i, mid + 1 + j]);

            if (leftArray[i] <= rightArray[j]) {
                array[k++] = leftArray[i++];
            } else {
                array[k++] = rightArray[j++];
            }
        }

        while (i < leftArray.length) {
            array[k++] = leftArray[i++];
        }

        while (j < rightArray.length) {
            array[k++] = rightArray[j++];
        }
    };

    const mergeSortHelper = (start: number, end: number): void => {
        if (start < end) {
            const mid = Math.floor((start + end) / 2);
            mergeSortHelper(start, mid);
            mergeSortHelper(mid + 1, end);
            merge(start, mid, end);
        }
    };

    mergeSortHelper(0, array.length - 1);

    return animations;
};

// Heap Sort
export const heapSort = (array: number[]): number[][] => {
    const animations: number[][] = [];

    const heapify = (n: number, i: number): void => {
        let largest = i;
        const left = 2 * i + 1;
        const right = 2 * i + 2;

        // Push indices to highlight during the sorting process
        animations.push([i, left]);
        animations.push([i, right]);

        if (left < n && array[left] > array[largest]) {
            largest = left;
        }

        if (right < n && array[right] > array[largest]) {
            largest = right;
        }

        if (largest !== i) {
            // Swap elements
            [array[i], array[largest]] = [array[largest], array[i]];

            // Push the indices and heights of the two elements being swapped
            animations.push([i, array[i], largest, array[largest]]);

            heapify(n, largest);
        }
    };

    const heapSortHelper = (): void => {
        const n = array.length;

        for (let i = Math.floor(n / 2) - 1; i >= 0; i--) {
            heapify(n, i);
        }

        for (let i = n - 1; i > 0; i--) {
            // Swap elements
            [array[0], array[i]] = [array[i], array[0]];

            // Push the indices and heights of the two elements being swapped
            animations.push([0, array[0], i, array[i]]);

            heapify(i, 0);
        }
    };

    heapSortHelper();

    return animations;
};

// Counting Sort
export const countingSort = (array: number[]): number[][] => {
    const animations: number[][] = [];

    const max = Math.max(...array);
    const min = Math.min(...array);

    const countArray: number[] = new Array(max - min + 1).fill(0);

    array.forEach((num) => {
        countArray[num - min]++;
    });

    let k = 0;

    for (let i = min; i <= max; i++) {
        while (countArray[i - min] > 0) {
            // Push index to highlight during the sorting process
            animations.push([k]);

            array[k++] = i;
            countArray[i - min]--;
        }
    }

    return animations;
};

// Radix Sort
export const radixSort = (array: number[]): number[][] => {
    const animations: number[][] = [];
    const n = array.length;

    const getMax = (): number => {
        let max = array[0];

        for (let i = 1; i < n; i++) {
            if (array[i] > max) {
                max = array[i];
            }
        }

        return max;
    };

    const countingSortForRadix = (exp: number): void => {
        const outputArray: number[] = new Array(n).fill(0);
        const countArray: number[] = new Array(10).fill(0);

        for (let i = 0; i < n; i++) {
            countArray[Math.floor(array[i] / exp) % 10]++;
        }

        for (let i = 1; i < 10; i++) {
            countArray[i] += countArray[i - 1];
        }

        for (let i = n - 1; i >= 0; i--) {
            const index = Math.floor(array[i] / exp) % 10;
            outputArray[countArray[index] - 1] = array[i];
            animations.push([countArray[index] - 1, outputArray[countArray[index] - 1], i, array[i]]);
            countArray[index]--;
        }

        for (let i = 0; i < n; i++) {
            array[i] = outputArray[i];
            animations.push([i, array[i]]);
        }
    };

    const radixSortHelper = (): void => {
        const max = getMax();

        for (let exp = 1; Math.floor(max / exp) > 0; exp *= 10) {
            countingSortForRadix(exp);
        }
    };

    radixSortHelper();

    return animations;
};

// Bucket Sort
export const bucketSort = (array: number[]): number[][] => {
    const animations: number[][] = [];
    const n = array.length;

    const min = Math.min(...array);
    const max = Math.max(...array);
    const bucketSize = 5;

    const createBuckets = (): number[][] => {
        const bucketCount = Math.floor((max - min) / bucketSize) + 1;
        const buckets: number[][] = new Array(bucketCount).fill(0).map(() => []);

        for (let i = 0; i < n; i++) {
            const bucketIndex = Math.floor((array[i] - min) / bucketSize);
            buckets[bucketIndex].push(array[i]);
            animations.push([i, buckets[bucketIndex].length - 1]);
        }

        return buckets;
    };

    const sortBuckets = (buckets: number[][]): void => {
        let k = 0;

        for (let i = 0; i < buckets.length; i++) {
            if (buckets[i].length > 0) {
                buckets[i].sort((a, b) => a - b);

                for (let j = 0; j < buckets[i].length; j++) {
                    array[k++] = buckets[i][j];
                    animations.push([k - 1, array[k - 1]]);
                }
            }
        }
    };

    const buckets = createBuckets();
    sortBuckets(buckets);

    return animations;
};
