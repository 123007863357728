import { bubbleSort, bucketSort, countingSort, heapSort, insertionSort, mergeSort, quickSort, radixSort, selectionSort, /* other sorting functions */ } from "./SortingAlgorithms";

export const SortingAlgorithms: Array<ISortingAlgorithms> = [
    { type: "bubbleSort", func: bubbleSort, title: "Bubble Sort", complexity: "O(n^2)" },
    { type: "quickSort", func: quickSort, title: "Quick Sort", complexity: "O(n log n)" },
    { type: "selectionSort", func: selectionSort, title: "Selection Sort", complexity: "O(n^2)" },
    { type: "insertionSort", func: insertionSort, title: "Insertion Sort", complexity: "O(n^2)" },
    { type: "mergeSort", func: mergeSort, title: "Merge Sort", complexity: "O(n log n)" },
    { type: "heapSort", func: heapSort, title: "Heap Sort", complexity: "O(n log n)" },
    { type: "countingSort", func: countingSort, title: "Counting Sort", complexity: "O(n + k)" },
    { type: "radixSort", func: radixSort, title: "Radix Sort", complexity: "O(nk)" },
    { type: "bucketSort", func: bucketSort, title: "Bucket Sort", complexity: "O(n^2)" },
]

export interface ISortingAlgorithms {
    type: string, func: (array: number[]) => number[][], title: string, complexity: string
}

export const MIN_RANGE = 5;
export const MAX_RANGE_MOBILE = 25;
export const MAX_RANGE_DESKTOP = 60;
