import React, { useState, useEffect } from 'react';
import { Button, Container, Slider, Typography, Select, MenuItem } from '@material-ui/core';
import './SortingVisualiser.css';
import { ISortingAlgorithms, MAX_RANGE_DESKTOP, MAX_RANGE_MOBILE, MIN_RANGE, SortingAlgorithms } from './Constants';

const SortingVisualizer: React.FC = () => {
    const [array, setArray] = useState<number[]>([]); // Update the state type to number[][]
    const [arraySize, setArraySize] = useState<number>(window.innerWidth > 767 ? 15 : 5);
    const [sortingAlgorithm, setSortingAlgorithm] = useState<ISortingAlgorithms>(SortingAlgorithms[0]);
    const [timeTaken, setTimeTaken] = useState<number | null>(null);
    const [complexity, setComplexity] = useState<string>(''); // Display the algorithm complexity
    const minRange = MIN_RANGE;
    const maxRange = window.innerWidth > 767 ? MAX_RANGE_DESKTOP : MAX_RANGE_MOBILE;

    useEffect(() => {
        resetArray();
        // eslint-disable-next-line
    }, [arraySize]);

    const handleArraySizeChange = (
        event: React.ChangeEvent<{}>,
        newSize: number | number[]
    ) => {
        setArraySize(typeof newSize === 'number' ? newSize : newSize[0]);
    };
    const handleAlgorithmChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSortingAlgorithm(SortingAlgorithms.find((algo) => { return algo.type === event.target.value }) as ISortingAlgorithms);
    };

    const getRandomNonRepeatingArray = (min: number, max: number, size: number): number[] => {
        if (size > max - min + 1) {
            throw new Error("Size should be less than or equal to the range of values");
        }

        const values = Array.from({ length: max - min + 1 }, (_, index) => index + min);

        for (let i = values.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [values[i], values[j]] = [values[j], values[i]];
        }
        return values.slice(0, size);
    };

    const resetArray = () => {
        const newArray: number[] = getRandomNonRepeatingArray(minRange, maxRange, arraySize);
        setTimeTaken(null);
        setComplexity("");
        setArray(newArray);
    };


    const handleSort = (algo: ISortingAlgorithms) => {
        let animations: number[][] = [];
        const startTime = performance.now();
        const arrayCopy = [...array];
        animations = algo.func(arrayCopy);
        const endTime = performance.now();
        setTimeTaken(endTime - startTime);
        setComplexity(algo.complexity);
        animateSorting(animations);
    }

    const animateSorting = (animations: number[][]) => {
        animations.forEach(([barIndex1, newHeight1, barIndex2, newHeight2], i) => {
            setTimeout(() => {
                setArray((prevArray) => {
                    const newArray = [...prevArray];
                    if (newHeight1 !== undefined) {
                        newArray[barIndex1] = newHeight1;
                    }
                    if (newHeight2 !== undefined) {
                        newArray[barIndex2] = newHeight2;
                    }
                    return newArray;
                });
            }, i * 70);
        });
        setTimeout(() => {
            setArray((prevArray) => [...prevArray].sort((a, b) => a - b));
        }, animations.length * 70);
    };

    const redirectToSubdomain = () => {
        if (window.location.hostname.includes("localhost")) {
            window.location.href = 'http://localhost:3001/';
        } else {
            window.location.href = 'https://karim.premieronetech.com/projects';
        }
    };
    return (
        <div className='base-container'>
            <Button variant="contained" color="primary" onClick={redirectToSubdomain} className='top-button'>
                Back to Portfolio
            </Button>
            <br />
            <Typography variant="h5" gutterBottom>
                Array Size: {arraySize}
            </Typography>
            <Slider
                value={arraySize}
                onChange={handleArraySizeChange}
                valueLabelDisplay="auto"
                step={1}
                min={minRange}
                aria-label='Choose Array Size'
                max={maxRange - 10}
                aria-labelledby="array-size-slider"
                style={{ maxWidth: "50%" }}
            />
            <div className="array-container">
                {array.map((value, index) => (
                    <div key={index} className="array-bar-container">
                        <div
                            className="array-bar"
                            style={{ height: window.innerWidth > 767 ? `${value * 7}px` : `${value * 10}px`, }} data-value={value}
                        ></div>
                    </div>
                ))}
            </div>
            <div className='sort-data-container '>
                <Typography variant="subtitle1" gutterBottom className="time-complexity">
                    Selected Algorithm: {sortingAlgorithm.title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom className="time-complexity">
                    Time Taken: {timeTaken !== null ? `${timeTaken.toFixed(2)} ms` : 'Not measured'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom className="time-complexity">
                    Complexity: {complexity}
                </Typography>
            </div>
            <div className='sorting-data'>
                <Button variant="contained" color="primary" onClick={resetArray} className='sort-data-button'>
                    Random Array
                </Button>
                <Select
                    value={sortingAlgorithm.type}
                    onChange={handleAlgorithmChange}
                    className="custom-select sort-data-button"
                >
                    {SortingAlgorithms.map((algo) => (
                        <MenuItem key={algo.type} value={algo.type}>
                            {algo.title}
                        </MenuItem>
                    ))}
                </Select>
                <Button variant="contained" color="secondary" onClick={() => handleSort(sortingAlgorithm)} className='sort-data-button'>
                    Sort
                </Button>
            </div>

        </div>
    );
};

export default SortingVisualizer;
